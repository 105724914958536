import React from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import ReactPlayer from 'react-player'

var previewTimeout = null;
class Destacado extends React.Component {

    state = {
        playing: false
    }


    handleMouseEnter = (e) => {
        const self = this;
        previewTimeout = setTimeout(() => {
            self.setState({ playing: true })
        }, 1500);
    }


    handleMouseLeave = (e) => {
        clearTimeout(previewTimeout);
    }


    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
    }


    render() {
        const { medio, ImagenAsociadaCategoria, Url } = this.props;
        //console.log("Url", Url)

        let ImagenAsociada = medio && medio.ImagenAsociada ?
            medio.ImagenAsociada :
            (ImagenAsociadaCategoria ? ImagenAsociadaCategoria : "/img/audio1200.jpg")
            ;

        let imagenAsociadaBG = {
            backgroundImage: `url("${ImagenAsociada}")`,
            height: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
        }


        const LinkReproducir = (medio ?
            <Link to={`/play/player/${medio.IdMedio}`}><em className="fa fa-caret-right mx-2"></em> Reproducir</Link>
            : null
        )


        return (
            <div className="destacadoPreview">

                {medio ?
                    (medio.Trailer && !this.props.AdminState.editandoMedio ?
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            playing={this.state.playing}
                            className="youtubeBackground"
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                            url={medio.Trailer}
                        />

                        :
                        medio.ImagenAsociada ?
                            <div style={imagenAsociadaBG} />
                            : null)
                    :

                    (ImagenAsociadaCategoria ? <div style={imagenAsociadaBG} /> : null)

                }





                <div className="NombreCategoria">
                    {this.props.Text ?
                        <>
                            <h1>  {Url ?
                                <a target="_blank" href={Url}> <em className="fa fa-external-link " /> {this.props.Text} </a>
                                : this.props.Text}

                            </h1>
                            {LinkReproducir}
                        </>
                        :
                        LinkReproducir
                    }
                </div>

            </div>
        )
    }
}




const mapStateToProps = ({ home, admin }) => {
    return {
        HomeState: home,
        AdminState: admin
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ShowNav: () => {
            dispatch({ type: 'SHOWNAV' })
        }
    }
}

const ConnectedDestacado = connect(
    mapStateToProps,
    mapDispatchToProps
)(Destacado)


export default ConnectedDestacado;



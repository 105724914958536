import React from "react"
import { Link, navigate } from 'gatsby'
import { connect } from "react-redux"
import EllipsisText from "react-ellipsis-text";
import Duration from './Duration'
import Rate from "./Rating/Rate"
import { siteMetadata } from '../../gatsby-config'


class Medio extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
  }

  state = {
    position: "center"
  }

  UpdateIsRight() {
    var BoundingClientRect = this.myRef.current.getBoundingClientRect();
    let parentXOffset = 0;
    if (this.props.parentContainer !== undefined) {
      parentXOffset = this.props.parentContainer.getBoundingClientRect().x;
    }

    //console.log(this.myRef.current);
    //console.log("BoundingClientRect", BoundingClientRect);
    //console.log(window.innerWidth, BoundingClientRect.x, BoundingClientRect.width)

    var result = false;
    if (typeof (window) != "undefined") {

      if ((BoundingClientRect.x - parentXOffset) < BoundingClientRect.width) {
        result = "left"
      }
      else if ((BoundingClientRect.x + BoundingClientRect.width) >= (window.innerWidth - BoundingClientRect.width)) {
        result = "right"
      }
      else {
        result = "center"
      }
      if (result != this.state.position) {
        this.setState({
          position: result
        });
      }
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  handleMouseEnter = (e) => {
    /*this.UpdateIsRight();*/
    e.stopPropagation();
    this.UpdateIsRight();
  }

  handleCommentsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { IdMedio } = this.props.medio;
    navigate(`/play/player/${IdMedio}#comments`);

  }


  getThumbnailSerie = (idSerie) => {
    const baseurl = siteMetadata.apiBaseURL;
    return baseurl + "/images/series/" + idSerie + "/thumbnail.jpg";
  }

  render() {
    const { medio } = this.props;

    var url = medio.UrlExterno;

    const isSoundCloud = url.indexOf("soundcloud.com") >= 0;

    //console.log("render Medio>>>>>>>>>>>", medio);
    let clases = ["medio"];
    clases.push(this.state.position);
    let showAlwaysTitle = !medio.ImagenAsociada || medio.UrlExterno.match(/soundcloud/gi);

    return (
      <div ref={this.myRef} onMouseEnter={this.handleMouseEnter} className="medioContainer d-flex">
        <div className={clases.join(" ")} id={"medio-" + medio.IdMedio}>
          <Link
            onClick={() => {
              this.props.HideInfo()
              this.props.AddToPlaylist(medio)
            }}
            to={"/play/player/" + medio.IdMedio}>



            {showAlwaysTitle
              ?
              <div className="StandarMediaInfo">
                <div className="tituloMedio" >
                  <EllipsisText text={medio.Titulo} length={60} />
                </div>
              </div>
              : null
            }

            <div className="medioDetails d-flex">
              <div className="DuracionMedio mb-2">
                <Duration seconds={medio.Duracion} />
              </div>


              <div className="row medioDetailsContent h-100 d-flex">
                <div className="col-sm-8 col-md-9 p-0 align-self-end">
                  <div className="row">
                    <div className="col">
                      <div className="playIconContainer mx-3">
                        <i className="fa fa-play-circle"></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="tituloMedio" >
                        <EllipsisText text={medio.Titulo} length={60} />
                      </div>
                      <div className="AsignaturaMedio  text-truncate d-sm-block d-none">
                        &nbsp;
                        {/*
                        <EllipsisText text="05089 APLIC.DE INF A LOS SERV REFER Y A FUENTES" length={50} />
                        */}
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-sm-4 col-md-3  d-sm-block d-none p-0 align-self-center">
                  <div className="ratingButtons">
                    <Rate medio={medio} />
                    <button onClick={this.handleCommentsClick} className="btn"><i className="fa fa-comment mb-2"></i>
                      <span className="d-none">Comentar</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mediaThumbnailContainer row h-100">
              <div className="col-sm-12 h-100 d-flex">
                <div className="text-center d-flex w-100">

                  <img className="mediaThumbnail m-auto" alt={medio.Titulo}
                    src={
                      medio.Serie > 0 && !medio.ImagenAsociada ?
                        this.getThumbnailSerie(medio.Serie) :
                        (medio.ImagenAsociada ?
                          medio.ImagenAsociada :
                          "/img/thubmnail-video.jpg"
                        )
                    }

                  />
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    AddToPlaylist: (medio) => {
      dispatch({ type: `ADDTOPLAYLIST`, medio })
    },
    HideInfo: () => {
      dispatch({ type: `HIDEPLAYERINFOCONTAINER` })
    }
  }
}

const ConnectedMedio = connect(
  mapStateToProps,
  mapDispatchToProps
)(Medio)



export default ConnectedMedio





